import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "bootstrap/dist/css/bootstrap.min.css";

const AboutMe = ({ location }) => (
  <Layout location={location}>
    <SEO title="About Me" />
    <p style={{fontSize: "150%"}}>I’m a programmer who specializes in the intersection between technology and art with three years of experience in the game industry and over ten years of professional programming experience.  </p>
    <p>I love to be busy solving difficult problems, and to learn by working side-by-side with skilled colleagues. I’ve created many personal projects to stretch my abilities from implementing graphics articles to modding games.  I’ve debugged with console dev-kits, contributed to many successful software releases, and built several UI tools.  I’m looking for a company that I can work with to help make our dreams something players can explore and get lost in.</p>

    <table class="verticalTable" style={{margin: "0 auto"}}>
      <tr>
        <th>Current Contract End Date</th>
        <td>Sept 13th, 2020</td>
      </tr>
      <tr>
        <th>Desired Work Location</th>
        <td>Tokyo</td>
      </tr>
      <tr>
        <th>Linkedin</th>
        <td>
          <a href="https://www.linkedin.com/in/shonferguson/" target="_blank" rel="noopener noreferrer">
            https://www.linkedin.com/in/shonferguson/
          </a>
        </td>
      </tr>
      <tr>
        <th>Github</th>
        <td>
          <a href="https://github.com/shonferg" target="_blank" rel="noopener noreferrer">
            https://github.com/shonferg
          </a>
        </td>
      </tr>
      <tr>
        <th>SourceForge</th>
        <td>
          <a href="https://sourceforge.net/u/liztail/profile/" target="_blank" rel="noopener noreferrer">
            https://sourceforge.net/u/liztail/profile/
          </a>
        </td>
      </tr>
      <tr>
        <th>メール</th>
        <td>
          <a href="mailto:contact@shonf.com" target="_blank" rel="noopener noreferrer">
            contact@shonf.com
          </a>
        </td>
      </tr>
    </table>
  </Layout>
);

export default AboutMe;
